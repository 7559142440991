import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field, Fragment } from 'Util/Query';

/** @namespace Sofacompany/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    getLinkedProductsQuery(options) {
        const { sku, type } = options;
        this.options = options;
        const field = `${ type }_products`;

        return new Field('products')
            .addArgument('filter', 'ProductAttributeFilterInput', {
                sku: { in: sku },
                allow_any_visibility: { eq: 'true' }
            })
            .addField(new Field('items')
                .addField('id')
                .addField('name')
                .addField(new Field(field).setAlias('products')
                    .addField('type_id')
                    .addField('id')
                    .addField('sku')
                    .addField('type_id')
                    .addField('stock_status')
                    .addField(new Field('short_description')
                        .addField('html'))
                    .addField('url_key')
                    .addField('url')
                    .addField('canonical_url')
                    .addField('name')
                    .addField('name2')
                    .addField('sofa_original_name')
                    .addField('sofa_ecomaison_fee')
                    .addField('special_price')
                    .addField(new Field('price_range')
                        .addField(new Field('minimum_price')
                            .addField(new Field('final_price')
                                .addField('value')
                                .addField('currency'))
                            .addField(new Field('regular_price')
                                .addField('value')
                                .addField('currency')))
                        .addField(new Field('maximum_price')
                            .addField(new Field('final_price')
                                .addField('value')
                                .addField('currency'))
                            .addField(new Field('regular_price')
                                .addField('value')
                                .addField('currency'))))));
    }

    _getArgumentsMap() {
        const { requireInfo } = this.options;
        const filterArgumentMap = this._getFilterArgumentMap();

        return {
            currentPage: { type: 'Int!' },
            showNoneVisibleProduct: { type: 'bool' },
            pageSize: {
                type: 'Int!',
                handler: (option) => (requireInfo ? 1 : option)
            },
            search: {
                type: 'String!',
                handler: (option) => encodeURIComponent(option)
            },
            sort: {
                type: 'ProductAttributeSortInput!',
                // eslint-disable-next-line max-len
                handler: ({ sortKey, sortDirection, sortMst }) => (sortMst ? `{"mst_sort": {"code": "${sortKey}", "dir": "${sortDirection || 'ASC'}"}}` : `{"${sortKey}": "${sortDirection || 'ASC'}"}`)
            },
            filter: {
                type: 'ProductAttributeFilterInput!',
                handler: (initialOptions = {}) => {
                    // add customer group by default to all requests
                    const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                    const options = {
                        ...initialOptions,
                        customerGroupId: group_id || '0'
                    };

                    const {
                        customFilters: { category_id } = {}
                    } = options;

                    /**
                     * Remove category ID from select, if there is a custom filter
                     * of category already selected in filtering options.
                     */
                    if (category_id) {
                        options.categoryIds = undefined;
                    }

                    const parsedOptions = Object.entries(options).reduce(
                        (acc, [key, option]) => {
                            // if there is no value, or if the key is just not present in options object
                            if (!option || !filterArgumentMap[key]) {
                                return acc;
                            }

                            return [...acc, ...filterArgumentMap[key](option)];
                        },
                        []
                    );

                    return `{${ parsedOptions.join(',') }}`;
                }
            }
        };
    }

    _getFilterArgumentMap() {
        return {
            allowAnyVisibility: () => ['"allow_any_visibility": { "eq": "true" }'],
            pagesize: (size) => [`"pageSize": ${size}`],
            categoryIds: (id) => [`"category_id": { "eq": "${id}" }`],
            categoryIdsArray: (id) => [`"category_id": { "in": [${ encodeURIComponent(id) }] }`],
            categoryUrlPath: (url) => [`"category_url_path": { "eq": "${url}" }`],
            priceRange: ({ min, max }) => {
                const filters = [];

                if (min && !max) {
                    filters.push(`"price": { "from": "${min}" }`);
                } else if (!min && max) {
                    filters.push(`"price": { "to": "${max}" }`);
                } else if (min && max) {
                    filters.push(`"price": { "from": "${min}", "to": "${max}" }`);
                }

                return filters;
            },
            sizeRange: (options) => {
                const filters = [];

                options.forEach((option) => {
                    const { type, max, min } = option;
                    if (min && !max) {
                        filters.push(`"${type}": { "from": "${min}" }`);
                    } else if (!min && max) {
                        filters.push(`"${type}": { "to": "${max}" }`);
                    } else if (min && max) {
                        filters.push(`"${type}": { "from": "${min}", "to": "${max}" }`);
                    }
                });

                return filters;
            },
            // eslint-disable-next-line max-len
            productSofaSeriesCode: (sofa_series_code) => [`"sofa_series_code": { "match": "${ encodeURIComponent(sofa_series_code) }" }`],
            productsSkuArray: (sku) => [`"sku": { "in": [${ encodeURIComponent(sku) }] }`],
            productSKU: (sku) => [`"sku": { "eq": "${ encodeURIComponent(sku) }" }`],
            // eslint-disable-next-line max-len
            fabricSample: (obj) => [`"category_id": { "eq": "${ encodeURIComponent(obj.fabricCategory) }" },"sku": { "eq": "${ encodeURIComponent(obj.fabricSku) }" }`],
            productName: (name) => [`"name": { "match":"${name}"}`],
            productUrlPath: (url) => [`"url_key": { "eq": "${url}"}`],
            customFilters: this._getCustomFilters,
            newToDate: (date) => [`"news_to_date": { "gteq": "${date}" }`],
            conditions: (conditions) => [`"conditions": { "eq": "${conditions}" }`]
        };
    }

    _getCustomFilters = (filters = {}) => (
        Object.entries(filters).reduce((acc, [key, attribute]) => {
            if (!attribute.length) {
                return acc;
            }

            if (key === 'price') {
                return [
                    ...acc,
                    this._getPriceFilter(key, attribute)
                ];
            }

            return [
                ...acc,
                `"${key}": { "in": [ ${attribute} ] } `
            ];
        }, [])
    );

    _getProductFields() {
        const {
            requireInfo, isSingleProduct, notRequireInfo, requirePriceRange
        } = this.options;

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            return [
                this._getItemsField(),
                'mageworx_seo_markup_product_price_valid_until_default_value'
            ];
        }

        // for filters only request
        if (requireInfo) {
            const infoFields = [
                this._getSortField(),
                this._getAggregationsField()
            ];

            if (requirePriceRange) {
                infoFields.push('sc_min_price', 'sc_max_price');
            }

            return infoFields;
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField()
        ];
    }

    _getCategoryFields() {
        return [
            'name',
            'url',
            this._getBreadcrumbsField(),
            'id',
            'category_seo_name',
            'url_key'

        ];
    }

    _getProductInterfaceFields(isVariant) {
        const {
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            isMediaOnly = false,
            isFabricOnly = false,
            isStoresOnly = false,
            isMissingDyoData = false,
            isUrlOnly = false
        } = this.options;

        if (isMediaOnly) {
            const fields = [
                'sku',
                this._getMediaGalleryField(),
                this._getStockItemFields()
            ];

            return fields;
        }

        if (isUrlOnly) {
            const fields = [
                'id',
                'sku',
                'url',
                'url_key',
                this._getUrlRewrites(),
                'sofa_fabric_name_label',
                'sofa_fabric_name'
            ];

            return fields;
        }

        if (isFabricOnly) {
            const fields = [
                'id',
                'name',
                'url',
                'url_key',
                this._getUrlRewrites(),
                'sofa_fabric_name_label',
                'sofa_fabric_name',
                this._getMediaGalleryField(),
                'fabric_image_url'
            ];

            return fields;
        }

        if (isStoresOnly) {
            const fields = [
                'name',
                'sku',
                'sofa_showroom_store_ids'
            ];

            return fields;
        }

        if (isMissingDyoData) {
            const fields = [
                'sofa_fabric_composition',
                'sofa_fabric_color_authenticity',
                'sofa_fabric_pilling',
                'sofa_fabric_martindale'
            ];

            return fields;
        }

        const fields = [
            'id',
            'sku',
            'sku_legacy',
            'sofa_series_code',
            'name',
            'name2',
            'type_id',
            'stock_status',
            'sofa_original_name',
            'cms_product',
            'sofa_fabric_name_label',
            'sofa_fabric_name',
            'sofa_ecomaison_fee',
            'canonical_url',
            'url_key',
            'cylindo_start_frame',
            'sofa_next_delivery_text',
            'delivery_calculated_days',
            'fast_delivery',
            'new_description',
            'sofa_product_family_code',
            'sofa_fabric_code',
            'sofa_designer_code',
            this._getRelatedProducts(),
            this._getPriceRangeField(),
            this._getCategoriesField(),
            this._getProductThumbnailField(),
            this._getProductSmallField(),
            this._getProductImageField(),
            this._getShortDescriptionField(),
            this._getStockItemFields(),
            this._getTierPricesField(),
            this._getBadges(isSingleProduct),
            this._getSofaVariants(),
            this._getCylindoAngledImageField(),
            this._getType2ImageField()
        ];

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            // fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewrites(),
                this._getGiftCardProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment()
                );
            }
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'meta_title',
                'meta_keyword',
                'meta_robots',
                this._getMwHrefLangs(),
                'meta_description',
                'sofa_legs',
                'sofa_legs_label',
                'sofa_height',
                'sofa_width',
                'sofa_depth',
                'sofa_length_of_chaiselong',
                'sofa_internal_seat_depth_chaiselong',
                'sofa_seat_depth_corner',
                'sofa_internal_seat_depth_corner',
                'sofa_unfolded_depth',
                'sofa_next_delivery_text',
                'delivery_calculated_days',
                'fast_delivery',
                'sofa_seating_depth',
                'sofa_inner_width',
                'sofa_seat_height',
                'sofa_fabric_composition',
                'sofa_fabric_martindale',
                'sofa_fabric_pilling',
                'sofa_fabric_color_authenticity',
                'sofa_designer_cms_block',
                this._getAssemblyInstructionsFields(),
                'sofa_is_make_me',
                'sofa_series_code',
                'sofa_fabric_code',
                'sofa_fabric_name_label',
                'sofa_cylindo_leg_name',
                'sofa_showroom_store_ids',
                'hide_360_viewer',
                'sofa_tagline',
                'new_description',
                'sofa_product_family_code',
                'sofa_fabric_code',
                'sofa_designer_code',
                'sofa_ecomaison_fee',
                this._getDescriptionField(),
                this._getSimpleProductFragment(),
                // this._getReviewSummaryField(),
                this._getCustomizableProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment()
                );
            }
        }

        return fields;
    }

    _getRelatedProducts() {
        const items = new Field('related_products')
            .addField('name')
            .addField('sku');

        return items;
    }

    _getMwHrefLangs() {
        const items = new Field('items')
            .addField('url')
            .addField('code');

        const mwHrefLangs = new Field('mw_hreflangs')
            .addField(items);

        return mwHrefLangs;
    }

    _getGiftCardProductFragment() {
        return new Fragment('GiftCardProduct')
            .addFieldList(this._getGiftCardProductFragmentFields());
    }

    _getGiftCardAmountsFields() {
        return [
            'value_id',
            'value'
        ];
    }

    _getGiftCardAmountsField() {
        return new Field('giftcard_amounts')
            .addFieldList(this._getGiftCardAmountsFields());
    }

    _getGiftCardProductFragmentFields() {
        return [
            this._getGiftCardAmountsField(),
            'allow_open_amount',
            'open_amount_min',
            'open_amount_max',
            'lifetime',
            'is_redeemable',
            'allow_message',
            'message_max_length',
            'giftcard_type'
        ];
    }

    _getCylindoAngledImageField() {
        return new Field('cylindo_angled_image').addField('url').addField('path');
    }

    _getType2ImageField() {
        return new Field('type_2').addField('url').addField('path');
    }

    _getAttributesField(isVariant) {
        return new Field('s_attributes')
            .addFieldList(this._getAttributeFields(isVariant));
    }

    _getAssemblyInstructionsFields() {
        return new Field('sofa_assembly_instructions')
            .addFieldList(['name', 'url']);
    }

    _getProductImageField() {
        return new Field('image')
            .addFieldList(this._getProductSmallFields());
    }

    _getStockItemFields() {
        return new Field('stock_item')
            .addFieldList(['qty', 'enable_qty_increments', 'qty_increments']);
    }

    _getConfigurableOptionSwatchDataField() {
        return new Field('swatch_data')
            .addFieldList(['value', '__typename']);
    }

    _getValueFields() {
        return [
            'value_index',
            'label',
            this._getConfigurableOptionSwatchDataField()
        ];
    }

    _getUrlRewrites() {
        return new Field('url_rewrites')
            .addFieldList(['url', this._getUrlRewritesParameters()]);
    }

    _getUrlRewritesParameters() {
        return new Field('parameters')
            .addFieldList(['name', 'value']);
    }

    _getBadges(isSingleProduct) {
        if (isSingleProduct) {
            const productItems = new Field('items')
                .addFieldList([
                    'product_font_style',
                    'product_position',
                    'product_position_style',
                    'product_value'
                ]);

            return new Field('product_badges')
                .addField(productItems);
        }

        const categoryItems = new Field('items')
            .addFieldList([
                'category_font_style',
                'category_position',
                'category_position_style',
                'category_value'
            ]);

        return new Field('category_badges')
            .addField(categoryItems);
    }

    _getSofaVariants() {
        const mediaGallery = new Field('media_gallery')
            .addFieldList([
                'label',
                'position',
                'url'
            ]);
        const materials = new Field('materials')
            .addFieldList([
                'type',
                'sku',
                'material_image'
            ]);

        const variantItems = new Field('items')
            .addFieldList([
                'id',
                mediaGallery,
                'name',
                'sku',
                'sofa_fabric_name_label',
                materials
            ]);

        return new Field('sofa_variants')
            .addField(variantItems);
    }

    _getSortOptionFields() {
        return [
            'value',
            'label',
            'mst_sort'
        ];
    }

    getShowroomStock(sku) {
        const query = new Field('GetAllShowroomStockCount')
            .addArgument('sku', 'String', sku)
            .addFieldList(['qty', 'showroom_code']);

        return query;
    }
}

export default new ProductListQuery();
