/* eslint-disable no-unused-vars */
import parsePhoneNumber from 'libphonenumber-js';
import { postcodeValidator } from 'postcode-validator';

import {
    isNotEmpty,
    MIN_PASSWORD_LENGTH,
    validateEmail,
    validateEmails,
    validatePassword,
    validatePasswordMatch
} from 'SourceComponent/Form/Form.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getLocaleFromUrl } from 'Util/Global';
import getStore from 'Util/Store';

export {
    MIN_PASSWORD_LENGTH,
    validateEmail,
    validateEmails,
    validatePassword,
    isNotEmpty,
    validatePasswordMatch
};

export const greenlandZipRange = { start: 3900, end: 3985 };

export const vatRegex = {
    dk: /(^DK\s?\d{8}|^\d{13})$/, // DK (DK12345678 or 1234567890123)
    se: /^SE\s?\d{10}[01]{2}$/, // SE (12 numbers/the last two are always 01)
    no: /^\d{9}$/, // 9 numbers
    nl: /^NL\s?\d{9}B\d{2}$/, // NL (9 numbers + the letter B + 2 numbers)
    be: /^BE\s?[01]\d{9}$/, // BE (0 then 9 numbers)
    ch: /^\d{9}$/, // 9 numbers
    de: /^DE\s?\d{9}$/, // DE (9 numbers)
    at: /^AT\s?U\d{8}$/, // AT: AT (U + 8 numbers)
    fr: /^FR\s?\d{11}$/ // FR (11 numbers)
};

export const localesWithRequiredAdressNumber = [
    'de-de',
    'de-ch',
    'de-at',
    'da-dk',
    'nl-nl',
    'nl-be'
];

export const MAX_DELIVERYCOMMMENT_LENGTH = 200;

/** @namespace Sofacompany/Component/Form/Config/validatevalidateDeliveryCommentPassword */
export const validatevalidateDeliveryCommentPassword = ({ value }) => value.length <= MAX_DELIVERYCOMMMENT_LENGTH;

export const charactersLeft = ({ value }) => `${value?.length ?? 0} / ${MAX_DELIVERYCOMMMENT_LENGTH}`;

/** @namespace Sofacompany/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value, attributes }) => {
    /* We use libphonenumber-js to validate phonenumbers.
        * URL: https://www.npmjs.com/package/libphonenumber-js
        */
    const { country_id } = attributes;
    if (country_id) {
        const { value: countryCode } = country_id;
        const formattedValue = parsePhoneNumber(value, countryCode);
        return formattedValue?.isPossible(); // Only checks if the length of the phone-number is correct.
    }

    // if country_id is not pressent use default regex, "+(country code number) and then 6 to 13 characters"
    return value.length > 0 && value.match(/^\+(?:[0-9-] ?){6,13}[0-9]$/);
};

/** @namespace Sofacompany/Component/Form/Config/validatePostCode */
export const validatePostCode = (e) => {
    const validCode = postcodeValidator(e.value, e.getAttribute('locale'));
    // eslint-disable-next-line fp/no-let
    let { ConfigReducer: { restrictedZipCodes } } = getStore().getState();

    if (restrictedZipCodes && restrictedZipCodes.length > 0) {
        if (e.getAttribute('locale') === 'NL') {
            restrictedZipCodes = restrictedZipCodes.map((zipRange) => ({
                range_from: zipRange.range_from.replace(/\D/g, ''),
                range_to: zipRange.range_to.replace(/\D/g, '')
            }));
        }
        const postcode = e.value.replace(/\D/g, '');
        // eslint-disable-next-line fp/no-let
        for (let i = 0; i < restrictedZipCodes.length; i++) {
            // eslint-disable-next-line max-len
            if (postcode >= restrictedZipCodes[i].range_from.replace(/\D/g, '') && postcode <= restrictedZipCodes[i].range_to.replace(/\D/g, '')) {
                return false;
            }
        }
    }

    return validCode;
};

export const validatePostCodeOptional = (e) => {
    if (e?.value?.length > 0) {
        return validatePostCode(e);
    }

    return true;
};
export const validateAddress = (e) => {
    const locale = getLocaleFromUrl();
    if (localesWithRequiredAdressNumber.includes(locale)) {
        return e.value.match(/^(?=.*[A-Za-z])(?=.*\d).+$/);
    }

    return isNotEmpty(e);
};

export const validateAddressMessage = () => {
    const locale = getLocaleFromUrl();
    return localesWithRequiredAdressNumber.includes(locale) ? __('Address is invalid') : __('This field is required!');
};

/** @namespace Sofacompany/Component/Form/Config/validateVat */
export const validateVat = ({ value }) => {
    const { storeConfig: { code } } = BrowserDatabase.getItem('config');

    return value.match(vatRegex[code]);
};

/** @namespace Sofacompany/Component/Form/Config/formConfig */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password should be at least %s characters long', MIN_PASSWORD_LENGTH)
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    postcode: {
        validate: validatePostCode,
        message: __('Postcode is invalid!')
    },
    postcodeOptional: {
        validate: validatePostCodeOptional,
        message: __('Postcode is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    addressField: {
        validate: validateAddress,
        message: validateAddressMessage()
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    deliverycomment: {
        validate: validatevalidateDeliveryCommentPassword,
        message: __('Delivery comment should be at less than %s characters long', MAX_DELIVERYCOMMMENT_LENGTH),
        subContent: charactersLeft
    },
    vat: {
        validate: validateVat,
        message: __('VAT number is invalid')
    }
});

export default formConfig();
